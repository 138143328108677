body,
ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
img,
br,
hr,
table,
tr,
td,
dl,
dt,
dd,
form {
  margin: 0;
  padding: 0;
}

.clear {
  clear: both;
  font-size: 0px;
}
ul,
li {
  list-style: none;
}
img {
  border: none;
}
/*一般链接*/
a {
  text-decoration: none;
  color: #111111;
}
a:hover {
  color: #555;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}
/*清除浮动*/
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  display: inline-block;
}
/* Hides from IE-mac \*/
* html .clearfix {
  height: 1%;
}
.clearfix {
  display: block;
}
/* End hide from IE-mac */

/*png css hack for ie6*/

div {
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: PingFang SC, PingFang SC-Medium;
  font-size: 0.14rem;
  box-sizing: border-box;
}

@primary-color:  #1890ff;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);