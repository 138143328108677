.header {
  height: 0.8rem;
  width: 100%;
  background-color: #000;
  position: sticky;
  top: 0;
  z-index: 999;
  .header_con {
    width: 12rem;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header_right {
      display: flex;
      justify-content: space-between;
      height: 100%;
      .menu {
        display: flex;
        .menu_item {
          height: 100%;
          margin-right: 0.4rem;
          color: #fff;
          font-size: 0.16rem;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
      .lang {
        height: 100%;
        display: flex;
        align-items: center;
        .quan {
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 50%;
          border: 1px solid transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 0.1rem;
          > img {
            width: 0.19rem;
            height: 0.19rem;
            cursor: pointer;
          }
          &:first-child {
            margin-left: 0;
          }
        }
        .zh_quan {
          border-color: #ef1c26;
        }
        .en_quan {
          border-color: #073dc2;
        }
        .jp_quan {
          border-color: #fe0000;
        }
        .ko_quan {
          border-color: #333c82;
        }
      }
    }
  }
  .menu_icon {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .header {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    padding: 0 10px;
    .header_con {
      display: none;
    }
    .menu_icon {
      display: block;

      > img {
        width: 20px;
        height: 16px;
      }
    }
  }

  .menu_drawer {
    .ant-drawer-body {
      padding: 0;
      background-color: #000;
      color: #fff;
      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        .logo {
          width: 20px;
          height: 22px;
        }
        .close {
          width: 16px;
          height: 16px;
        }
      }
      .productment {
        .menu {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          > h2 {
            font-size: 18px;
            font-weight: 700;
            color: #ffc32e;
          }
          > a {
            display: block;
            width: 170px;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            border: 1px solid #fff;
            border-radius: 4px;
            margin-top: 10px;
            color: #fff;
            text-align: center;
          }
        }
        .guidelines {
          display: block;
          width: 170px;
          height: 30px;
          line-height: 30px;
          font-size: 18px;
          border: 1px solid #ffc32e;
          border-radius: 4px;
          font-weight: 700;
          margin: 0 auto;
          margin-top: 20px;
          color: #ffc32e;
          text-align: center;
        }
        .lang {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          .quan {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid transparent;
            margin-right: 10px;
            > img {
              width: 20px;
              height: 20px;
            }
            &:last-child {
              margin-right: 0;
            }
          }
          .zh_quan {
            border-color: #ef1c26;
          }
          .en_quan {
            border-color: #073dc2;
          }
          .jp_quan {
            border-color: #fe0000;
          }
          .ko_quan {
            border-color: #333c82;
          }
        }
      }
    }
  }
}

@primary-color:  #1890ff;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);