.footer {
  width: 100%;
  margin-top: 1rem;
  .footer_top {
    width: 12.8rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .footer_left {
      > a {
        > img {
          width: 1.92rem;
          height: 0.36rem;
        }
      }
    }
    .footer_right {
      display: flex;
      .navigation {
        margin-left: 0.5rem;
        > h2 {
          font-size: 0.2rem;
          line-height: 0.25rem;
          font-weight: 700;
          margin-bottom: 0.2rem !important;
          cursor: pointer;
        }
        > a {
          display: block;
          font-size: 0.16rem;
          color: #5f5f5f;
          line-height: 0.4rem;
          &:hover {
            color: #ffc32e;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .img_href {
          width: 0.77rem;
          height: 0.4rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > a {
            display: inline-block;
            width: 0.3rem;
            height: 0.3rem;
            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .footer_right_mobile {
      display: none;
    }
  }
  .footer_bom {
    width: 100%;
    height: 0.6rem;
    margin-top: 0.2rem;
    background-color: #000000;
    > a {
      display: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .footer {
    margin-top: 30px;
    .footer_top {
      width: 100%;

      .footer_left {
        display: none;
      }
      .footer_right {
        display: none;
      }
      .footer_right_mobile {
        display: block;
        width: 100%;

        .navigation {
          width: 320px;
          border: 1px solid #000000;
          border-radius: 8px;
          margin: 0 auto;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 15px;
          .navigation_left {
            width: 100%;
            text-align: center;
            padding-right: 5px;
            > h2 {
              font-size: 14px;
              font-weight: 700;
            }
          }
          .navigation_xian {
            width: 30px;
            height: 1px;
            background-color: #000;
            margin: 10px 0;
          }
          .navigation_right {
            width: 100%;
            padding-left: 5px;
            display: flex;
            flex-wrap: wrap;
            > a {
              width: 50%;
              line-height: 20px;
              font-size: 12px;
              text-align: center;
            }
          }
          &:nth-child(2) {
            .navigation_right {
              > a {
                flex: 1;
                &:first-child {
                  width: 100px;
                }
              }
            }
          }
          &:nth-child(3) {
            .navigation_right {
              > a {
                width: 100%;
              }
            }
          }
          &:nth-child(4) {
            .navigation_right {
              display: flex;
              justify-content: center;
              > a {
                width: 30px;
                display: flex;
                align-items: center;
                > img {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }
    }
    .footer_bom {
      height: 25px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      > a {
        display: block;
        width: 65px;
        height: 12px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@primary-color:  #1890ff;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);