.home {
  width: 100%;
  color: #000;
  .banner {
    width: 9.4rem;
    height: 3rem;
    margin: 0.5rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .banner_left {
      .banner_title {
        display: flex;
        .banner_title_left {
          width: 1.44rem;
          height: 0.63rem;
          background: url("../../assets/rectangletwo.png") no-repeat;
          background-size: 100% 100%;
          position: relative;
          top: 0;
          font-size: 0.36rem;
          font-style: italic;
          text-align: center;
          line-height: 0.63rem;
          .banner_title_hei {
            width: 1.44rem;
            height: 0.63rem;
            background: url("../../assets/rectangleone.png");
            background-size: 100% 100%;
            position: absolute;
            top: 2px;
            left: 2px;
            z-index: -1 !important;
          }
        }
        .banner_title_right {
          width: 2.8rem;
          height: 0.66rem;
          margin-left: 0.1rem;
          text-align: center;
          line-height: 0.66rem;
          font-size: 0.3rem;
          font-weight: 700;
          font-style: italic;
        }
        // width: 3.95rem;
        // height: 0.67rem;
        // > img {
        //   width: 100%;
        //   height: 100%;
        // }
      }
      .banner_text {
        width: 4.12rem;
        font-size: 0.16rem;
        line-height: 0.3rem;
        margin: 0.15rem 0;
        margin-bottom: 0.15rem !important;
      }
      .banner_download {
        width: 2rem;
        height: 0.34rem;
        border: 1px solid #000000;
        border-radius: 0.04rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.16rem;
        color: #0d0d0d;
        cursor: pointer;
        > img {
          width: 0.16rem;
          height: 0.2rem;
          margin-left: 0.15rem;
        }
      }
    }
    .banner_right {
      width: 5.1rem;
      height: 3rem;
      margin-left: 0.18rem;
      background: url("../../assets/banner.png");
      background-size: 100% 100%;
    }
  }
  .ability {
    width: 7.55rem;
    margin: 0 auto;
    margin-top: 0.5rem;
    .ability_box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.3rem;
      .ability_box_con {
        width: 3.28rem;
        .ability_title {
          font-size: 0.24rem;
          font-weight: 700;
          line-height: 0.3rem;
        }
        .ability_xian {
          width: 1rem;
          height: 1px;
          background-color: #000;
          margin: 0.15rem 0;
        }
        .ability_text {
          font-size: 0.16rem;
        }
      }
      &:nth-child(1) {
        > img {
          width: 3.2rem;
          height: 1.72rem;
        }
      }
      &:nth-child(2) {
        flex-direction: row-reverse;
        > img {
          width: 3.15rem;
          height: 1.85rem;
        }
      }
      &:nth-child(3) {
        > img {
          width: 2.58rem;
          height: 2.13rem;
        }
      }
    }
  }
  .find {
    width: 100%;
    height: 4rem;
    background-color: #ffc32e;
    margin: 0.5rem 0 0.4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .find_con {
      width: 7.79rem;
      height: 2.83rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .find_con_left {
        width: 4.7rem;
        .find_con_title {
          font-size: 0.45rem;
          line-height: 0.6rem;
          font-weight: 700;
          font-style: italic;
          .wrap {
            display: block;
          }
        }
        .find_con_text {
          font-size: 0.2rem;
          line-height: 0.3rem;
        }
      }
      .pc_finglogo {
        width: 2.6rem;
        height: 2.83rem;
      }
      .mb_finglogo {
        display: none;
      }
    }
  }
  .download_con {
    margin: 0.4rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .download_title {
      font-size: 0.24rem;
      font-weight: 700;
      margin-bottom: 0.2rem !important;
    }
    .download_btn {
      width: 3.56rem;
      height: 0.6rem;
      line-height: 0.6rem;
      font-size: 0.22rem;
      font-weight: 700;
      border-radius: 0.3rem;
      text-align: center;
      background-color: #000;
      color: #fff;
    }
    .download_instructions {
      font-size: 0.14rem;
      line-height: 0.17rem;
      display: flex;
      align-items: center;
      margin-top: 0.1rem;
      > img {
        width: 0.16rem;
        height: 0.17rem;
        margin-right: 0.05rem;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .home {
    .banner {
      width: 100%;
      height: auto;
      margin: 10px 0;
      display: flex;
      flex-direction: column-reverse;
      .banner_left {
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        .banner_title {
          display: flex;
          width: auto;
          .banner_title_left {
            width: auto;
            height: 36px;
            line-height: 36px;
            font-size: 30px;
            font-weight: 700;
            background: none;
            .banner_title_hei {
              display: none;
            }
          }
          .banner_title_right {
            width: 140px;
            height: 36px;
            line-height: 36px;
            font-size: 30px;
            text-align: center;
            color: #ffc32e;
            background: url("../../assets/rectangletherr.png");
            background-size: 100% 100%;
          }
          // width: 3.95rem;
          // height: 0.67rem;
          // > img {
          //   width: 100%;
          //   height: 100%;
          // }
        }
        // .banner_title {
        //   width: 238px;
        //   height: 37px;
        //   > img {
        //     width: 100%;
        //     height: 100%;
        //   }
        // }
        .banner_text {
          width: 300px;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          margin-top: 10px;
        }
        .banner_download {
          display: none;
        }
      }
      .banner_right {
        width: 360px;
        height: 218px;
        background: url("../../assets/mobilebanner.svg");
        background-size: 100% 100%;
      }
    }
    .ability {
      width: 100%;
      margin-top: 0.5rem;
      .ability_box {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        .ability_box_con {
          width: 320px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
          .ability_title {
            font-size: 16px;
            font-weight: 700;
            line-height: 30px;
          }
          .ability_xian {
            width: 50px;
            margin: 10px 0;
          }
          .ability_text {
            font-size: 12px;
            line-height: 18px;
            text-align: center;
          }
        }
        &:nth-child(1) {
          > img {
            width: 160px;
            height: 85px;
          }
        }
        &:nth-child(2) {
          flex-direction: column-reverse;
          > img {
            width: 158px;
            height: 92px;
          }
        }
        &:nth-child(3) {
          > img {
            width: 128px;
            height: 108px;
          }
        }
      }
    }
    .find {
      width: 100%;
      height: 205px;
      background-color: #ffc32e;
      margin: 0;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      .find_con {
        width: 100%;
        height: 175px;
        padding: 0 20px;
        position: relative;
        .find_con_left {
          width: 100%;
          .find_con_title {
            font-size: 0.58rem;
            line-height: 0.7rem;
            font-weight: 700;
            font-style: italic;
            .wrap {
              display: block;
            }
          }
          .find_con_text {
            margin-top: 10px;
            font-size: 12px;
            line-height: 18px;
          }
        }
        > img {
          position: absolute;
          top: 10px;
          right: 0;
        }
        .pc_finglogo {
          display: none;
        }
        .mb_finglogo {
          display: block;
          width: 200px;
          height: auto;
        }
      }
    }
    .download_con {
      margin: 30px auto;
      .download_title {
        font-size: 12px;
        margin-bottom: 10px !important;
      }
      .download_btn {
        width: 200px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        font-weight: 700;
        border-radius: 15px;
        text-align: center;
        background: #fff;
        border: 1px solid #000;
        color: #000;
      }
      .download_instructions {
        font-size: 12px;
        line-height: 16px;
        margin-top: 10px;
        > img {
          width: 8px;
          height: 10px;
          margin-right: 5px;
        }
      }
    }
  }
}

@primary-color:  #1890ff;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);