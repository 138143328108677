.drop_down_menu {
  height: 100%;
  color: #fff;
  position: relative;
  display: block;
  .drop_down_menu_title {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    > .title {
      font-size: 0.16rem;
    }
    > img {
      margin-left: 0.05rem;
      width: 0.12rem;
      height: 0.06rem;
      transition: all 0.2s;
    }
  }
  .drop_down_menu_title_active {
    > img {
      transform: rotateZ(180deg);
    }
  }
  .drop_down_menu_box {
    position: absolute;
    top: 0.6rem;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    padding: 0.1rem 0;
    background-color: #fff;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0.05rem;
    display: none;
    .drop_down_menu_item {
      box-sizing: border-box;
      display: block;
      height: 0.4rem;
      line-height: 0.4rem;
      padding: 0 0.15rem;
      white-space: nowrap;
      &:hover {
        color: #ffc32e;
      }
    }
    .drop_down_menu_item_active {
      color: #ffc32e;
    }
  }
  .drop_down_menu_box_active {
    display: block;
  }
}

@primary-color:  #1890ff;@link-color:  #1890ff;@success-color:  #52c41a;@warning-color:  #faad14;@error-color:  #f5222d;@font-size-base:  14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color:  rgba(0, 0, 0, 0.65);@text-color-secondary:  rgba(0, 0, 0, 0.45);@disabled-color:  rgba(0, 0, 0, 0.25);@border-radius-base:  2px;@border-color-base:  #d9d9d9;@box-shadow-base:  0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 9px 28px 8px rgba(0, 0, 0, 0.05);